import { ProColumns } from 'src/app/common/components/ProTable';
import { renderExpoButton } from '../components/talkbot-export-button/talkbot-export-button.component';
import { renderOperationHeader } from '../../pages/export-leads/export-leads-list/export-leads-list.screen';
import { CampaignStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';
import moment from 'moment';

export const getExportOperation = (t: (key: string, values?: Record<string, any>) => string, showTooltip = true) => {
  return {
    title: !showTooltip ? 'section.common.operation' : renderOperationHeader(t),
    sticky: true,
    dataIndex: 'operation',
    width: '176px',
    render: (currValue: string, item: any, actions: any) => renderExpoButton({ item, actions }),
  };
};

export const addExportToColumn = (
  columns: any[],
  t: (key: string, values?: Record<string, any>) => string,
  showTooltip = true,
): ProColumns<any>[] => {
  const newColumn = [...columns];
  newColumn.pop();
  const operation = getExportOperation(t, showTooltip);
  newColumn.push(operation);
  return newColumn;
};

export const groupedExportData = (data: any[]): any[] => {
  const groupedData = data?.reduce((acc, curr) => {
    const key = curr?.campaign?.talkbotRule?.flowId;
    (acc[key] ? acc[key] : (acc[key] = [])).push(curr);
    return acc;
  }, {});
  return Object.values(groupedData);
};

export const getEligibleLeads = (leads: any[], activeFlowIds?: number[]) => {
  const eligibleLeads = leads?.filter((lead) => {
    const { campaign, talkbotExported } = lead;
    return (
      campaign?.status === CampaignStatusEnum.active &&
      activeFlowIds?.includes(campaign?.talkbotRule?.flowId) &&
      !talkbotExported &&
      lead.leadRaw &&
      moment().isBetween(campaign?.startDate, campaign?.endDate) &&
      (lead.prospect && lead.prospect.hasOwnProperty('marketingConsent') ? (lead.prospect.marketingConsent) : (lead?.leadRaw?.pdpaConsent)) // PACS Customization | PACSPFAP-3741 | Disable auto export to talkbot after accepting a lead with marketing consent - NO
    );
  });
  return eligibleLeads;
};

export const isBirthdayWithin30Days = (birthDate: string) => {
  const now = moment();
  const curYearBirthday = moment(birthDate).year(now.year());
  const nextYearBirthday = moment(birthDate).year(now.year() + 1);
  const nextBirthday = curYearBirthday.isBefore(now, 'day') ? nextYearBirthday : curYearBirthday;
  const future30Days = now.add(30, 'd');
  return nextBirthday.isSameOrBefore(future30Days);
};
